<template>
  <div class="mt-4">
    <div class="d-flex mt-4 align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="file-lock2-fill" />
        {{ $t("Language Select") }}
      </h1>
    </div>

    <b-card class="mt-4 shadow-sm">
      <b-form-select
        v-model="$root.$i18n.locale"
        :options="options"
        v-on:change="getSelectedItem"
      ></b-form-select>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Language",
  data() {
    return {
      options: [
        { value: "en", text: "English" },
        { value: "de", text: "Deutsch" },
      ],
    };
  },
  methods: {
    getSelectedItem(localeCode) {
      localStorage.setItem("lang", localeCode);
    },
  },
};
</script>
